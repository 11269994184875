import BaseService from "../BaseService";

/**
 * login相关
 */
class SignInService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 发送签章验证码
   * @param params 参数
   */
  sendNotes(params) {
    // const { http } = this;
    // console.log('cccccccc')
    return this.http.post("zjSendNotes", params);
  }

  /**
   * 确定签章验证码
   * @param params 参数
   */
  suerNotes(params) {
    // const { http } = this;
    return this.http.post("zjSureNotes", params);
  }
}

export default SignInService;
