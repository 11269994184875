<template>
  <el-dialog :visible.sync="dialogVisible" width="700px" :append-to-body="true" :close-on-click-modal="false" class="oldphone-alert">
    <div class="top">
       <img class="img" src="@/assets/images/tzgly.png" alt="">
       <div class="title-box">
          <div class="title-top">短信验证码</div>
          <div class="title-bottom">[安心签]您正通过湖南省中小企业融资服务股份有限公司向安心签提交签署申请，验证码为:</div>
       </div>
    </div>
    <div class="code-box">
      <codeVer @complete="complete"></codeVer>
    </div>

    <span slot="footer" class="dialog-footer">
       <en-button @click="ok" type="primary" >确 定</en-button>
    </span>
 </el-dialog>
</template>

<script>
import codeVer from "./verification-code.vue";

export default {
  name: "SignatureDialog",
  components: { codeVer },
  data() {
    return {
      code: []
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    complete(value) {
      this.code = value;
    },
    ok() {
      this.$emit("ok", {
        code: this.code,
        isShow: false
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.top{
  display:flex;
  margin-top: 20px;
  .img{
    width: 40px;
  }
  .title-box{
    margin-left: 10px;
  }
  .title-top{
    font-size: 16px;
    font-weight: 500;
  }
  .title-bottom{
    font-size: 14px;
  }
  .code-box{
    width: 100%;
    text-align: center;
  }
}
</style>
