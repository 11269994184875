<!--
 * @Author: pengyu
 * @Date: 2020-07-31 10:55:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-23 15:08:51
 * @Description: file content
-->
<template>
  <div class="upload-box">
      <div v-if="column.produceType === 5" class="btn-box">
        <span class="identification" :style="{'background':identiferInfo.bg,'color':identiferInfo.color}">{{ identiferInfo.title }}</span>
        <en-button v-if="column.columnValue.length && !column.columnValue[0].gzState" title="签章"  icon="iconshenhe1" icon-color="#97b4f0" @click="toSignature"></en-button>
      </div>
      <en-attachment
        class="upload-control"
        :mode="column.editable ? 'edit' : 'show'"
        :data="column.columnValue"
        :column="column"
        :mdConfig="mdConfig"
        :busData="businessData"
        :calcMaxShowNum="true"
        @change="handleUpload"
        @deleteFile="handleDelete"
      ></en-attachment>
      <!-- 签章弹窗 -->
      <signature :dialogVisible="dialogVisible" @ok="ok"></signature>
  </div>
</template>

<script>
import { signatureService } from "@/api/signature/index.js";
import enAttachment from "@/components/en-attachment";
import signature from "./signature-dialog.vue";
import { AssignmentOptions } from "../../../data/BusinessCommonHeader.js";

export default {
  name: "BMAttachColumn",
  components: { enAttachment, signature },
  props: {
    column: {
      type: Object,
      require: true
    },
    // 可能需要的整体数据
    businessData: {
      type: Object
    }
  },
  computed: {
    mdConfig() {
      const { powerList } = this.businessData;
      const power = powerList.filter((itm) => itm.field === this.column.field)[0];
      return {
        ...power,
        ...this.column
      };
    },
    identiferInfo() {
      if (this.column.columnValue.length && this.column.columnValue[0].gzState) {
        return {
          title: "Sigend",
          bg: "#dcfcf1",
          color: "#56caa3"
        };
      }
      if (this.column.columnValue.length) {
          return {
            title: "uploaded",
            bg: "#e5f2fb",
            color: "#5294de"
          };
      }
        return {
            title: "Not uploaded",
            bg: "#fff5e5",
            color: "#f7be26"
          };
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [] // 文件列表
    };
  },
  watch: {
    "column.columnValue": {
      immediate: true,
      handler(value) {
        // eslint-disable-next-line no-bitwise
        if (this.column.lastAssignment & AssignmentOptions.server || this.column.lastAssignment & AssignmentOptions.automaticAssignment) {
          this.fileList = [...value];
        }
      }
    }
  },
  methods: {
    /**
     * @description: 发送签章验证码
     * @param param: 接口参数
     */
    async toSignature() {
      const param = {
        templateId: this.businessData.mdInfo.templateId,
        version: this.businessData.mdInfo.version,
        mdDataStr: this.businessData.mdData,
        isAxqUniqueContentCode: new Date().getTime(),
        ids: this.businessData.id
      };
      const res = await signatureService.sendNotes(param);
      if (res) this.dialogVisible = true;
    },
    /**
     * @description: 确认签章， 以及关闭弹窗
     * @param param: 接口参数
     */
    async ok(info) {
      if (info.code.length === 6) {
        const param = {
          templateId: this.businessData.mdInfo.templateId,
          version: this.businessData.mdInfo.version,
          mdDataStr: this.businessData.mdData,
          isAxqUniqueContentCode: new Date().getTime(),
          ids: this.businessData.id,
          checkCode: info.code.join(""),
          attachmentField: this.column.field
        };
        const res = await signatureService.sureNotes(param);
        console.log(res);
      }
      this.dialogVisible = info.isShow;
    },
    /**
     * @description: 上传成功回调
     * @param data:
     */
    handleUpload(file) {
      this.fileList = [...file];
      this.column.updateValueWithOptions(this.fileList, AssignmentOptions.manual);
    },
    /**
     * @description: 删除文件
     * @param file:
     */
    handleDelete(file) {
      const index = this.fileList.findIndex((item) => item.code === file.code);
      this.fileList.splice(index, 1);
      this.column.updateValueWithOptions(this.fileList, AssignmentOptions.manual);
    }
  },
  mounted() {
    console.log(this.column, this.businessData, "columncolumncolumncolumncolumn");
  }
};
</script>

<style lang='scss' scoped>
.upload-box{
  display: flex;
  .btn-box{
    display: flex;
    .identification{
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
  .upload-control{
    flex: 1;
  }
}
</style>
