var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-box" },
    [
      _vm.column.produceType === 5
        ? _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "span",
                {
                  staticClass: "identification",
                  style: {
                    background: _vm.identiferInfo.bg,
                    color: _vm.identiferInfo.color,
                  },
                },
                [_vm._v(_vm._s(_vm.identiferInfo.title))]
              ),
              _vm.column.columnValue.length &&
              !_vm.column.columnValue[0].gzState
                ? _c("en-button", {
                    attrs: {
                      title: "签章",
                      icon: "iconshenhe1",
                      "icon-color": "#97b4f0",
                    },
                    on: { click: _vm.toSignature },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("en-attachment", {
        staticClass: "upload-control",
        attrs: {
          mode: _vm.column.editable ? "edit" : "show",
          data: _vm.column.columnValue,
          column: _vm.column,
          mdConfig: _vm.mdConfig,
          busData: _vm.businessData,
          calcMaxShowNum: true,
        },
        on: { change: _vm.handleUpload, deleteFile: _vm.handleDelete },
      }),
      _c("signature", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: { ok: _vm.ok },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }