var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "oldphone-alert",
      attrs: {
        visible: _vm.dialogVisible,
        width: "700px",
        "append-to-body": true,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "top" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("@/assets/images/tzgly.png"), alt: "" },
        }),
        _c("div", { staticClass: "title-box" }, [
          _c("div", { staticClass: "title-top" }, [_vm._v("短信验证码")]),
          _c("div", { staticClass: "title-bottom" }, [
            _vm._v(
              "[安心签]您正通过湖南省中小企业融资服务股份有限公司向安心签提交签署申请，验证码为:"
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "code-box" },
        [_c("codeVer", { on: { complete: _vm.complete } })],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            { attrs: { type: "primary" }, on: { click: _vm.ok } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }